import PropTypes from 'prop-types'
import React from 'react'
import gallery01 from '../images/gallery-1.jpg'
import gallery02 from '../images/gallery-2.jpeg'
import gallery03 from '../images/gallery-3.jpg'
import gallery04 from '../images/gallery-4.jpg'
import gallery05 from '../images/gallery-5.jpg'
import gallery06 from '../images/gallery-6.jpg'
import gallery07 from '../images/gallery-7.jpg'
import gallery08 from '../images/gallery-8.jpg'
import gallery09 from '../images/gallery-9.jpg'
import gallery10 from '../images/gallery-10.jpg'
import gallery11 from '../images/gallery-11.jpg'
import gallery12 from '../images/gallery-12.jpg'
import gallery13 from '../images/gallery-13.jpg'
import gallery14 from '../images/gallery-14.jpg'
import gallery15 from '../images/gallery-15.jpg'
import gallery16 from '../images/gallery-16.jpg'
import gallery17 from '../images/gallery-17.jpeg'
import gallery18 from '../images/gallery-18.jpeg'
import gallery19 from '../images/gallery-19.jpeg'
import gallery20 from '../images/gallery-20.jpg'
import gallery21 from '../images/gallery-21.jpg'
import gallery22 from '../images/gallery-22.jpg'
import gallery23 from '../images/gallery-23.jpg'
import gallery24 from '../images/gallery-24.jpg'

import eyebrow from '../images/eybrow.jpg'
import wedding from '../images/wedding.jpg'
import lashLift from '../images/lash-lift.jpg'
import proMakeup from '../images/professional-makeup.jpeg'
import photoShoot from '../images/photoshooting-makeup.jpg'
import bio from '../images/bio.jpg'
class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Bio</h2>
          <span className="image main">
            <img src={bio} alt="Stamy Li" />
          </span>
          <p>
          “Makeup is not a mask that covers up your beauty, it’s a weapon that helps you express who you are from the inside. - ”Michelle Phan</p>
          <p>Πάντα θυμάμαι τον εαυτό μου, από μικρό παιδί ακόμα, να έχω έντονη δημιουργικότητα και ανήσυχη καλλιτεχνική φύση.
            Σπούδασα αισθητική και επαγγελματικό μακιγιάζ και βρίσκομαι στο χώρο της ομορφιάς και συγκεκριμένα του μακιγιάζ τα τελευταία 15 χρόνια. Ξεκίνησα την πορεία μου δουλεύοντας παράλληλα με την σχολή μου, με φωτογάφους είτε για editorial μοδας είτε για περιοδικά. 
            Στη συνέχεια εργάστηκα ως beauty consultant στην clinique συνεχίζοντας φυσικά να εργάζομαι ως freelancer Make-up Artist.
            Ακολούθησε πολυετής συνεργασία με την MAC όπου και είχα τη δυνατότητα να συμμετάσχω σαν backstage make-up artist σε fashion shows γνωστών σχεδιαστών μόδας όπως Στέλιος Κουδουνάρης, Βρετός Βρετάκος, Βασίλης Ζούλιας, Δημήτρης Πέτρου, Σωτήρης Γεωργίου κ.α.
            Σε όλη την επαγγελματική μου πορεία, έχω επενδύσει ιδιαίτερα  στην γνώση των προϊόντων  και του δέρματος, μιας και αυτό που πιστεύω είναι πως κάθε πρόσωπο, κάθε δέρμα χρειάζεται ξεχωριστή μοναδική φροντίδα. Αυτό χτίστηκε με τα χρόνια εμπειρίας και έχοντας “αντιμετωπίσει”  δέρματα όλων των αναγκών.
            Αυτό που αναζητούμε όλοι μας στη συνεργασία είναι η καλή επικοινωνία!
            Προσωπικός μου στόχος είναι κάθε πελάτισσα  να νιώθει πως παραμένει ο εαυτός της και το όραμα της για την κάθε περίσταση να είναι ακριβώς όπως το είχε φανταστεί, έτσι το αποτέλεσμα είναι η καλύτερη επιβράβευση για την εμπιστοσύνη που μου δείχνει!
          </p>
          {close}
        </article>
        <article
          id="gallery"
          className={`${this.props.article === 'gallery' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Gallery</h2>
          <div className="gallery-cnt">
          <span className="image main">
            <img src={gallery01} alt="Gallery1" />
          </span>
          <span className="image main">
            <img src={gallery02} alt="Gallery2" />
          </span>
          <span className="image main">
            <img src={gallery03} alt="Gallery2" />
          </span>
          <span className="image main">
            <img src={gallery04} alt="Gallery2" />
          </span>
          <span className="image main">
            <img src={gallery05} alt="Gallery2" />
          </span>
          <span className="image main">
            <img src={gallery06} alt="Gallery2" />
          </span>
          <span className="image main">
            <img src={gallery07} alt="Gallery2" />
          </span>
          <span className="image main">
            <img src={gallery08} alt="Gallery2" />
          </span>
          <span className="image main">
            <img src={gallery09} alt="Gallery2" />
          </span>
          <span className="image main">
            <img src={gallery10} alt="Gallery2" />
          </span>
          <span className="image main">
            <img src={gallery11} alt="Gallery2" />
          </span>
          <span className="image main">
            <img src={gallery12} alt="Gallery2" />
          </span>
          <span className="image main">
            <img src={gallery13} alt="Gallery2" />
          </span>
          <span className="image main">
            <img src={gallery14} alt="Gallery2" />
          </span>
          <span className="image main">
            <img src={gallery15} alt="Gallery2" />
          </span>
          <span className="image main">
            <img src={gallery16} alt="Gallery2" />
          </span>
          <span className="image main">
            <img src={gallery17} alt="Gallery2" />
          </span>
          <span className="image main">
            <img src={gallery18} alt="Gallery2" />
          </span>
          <span className="image main">
            <img src={gallery19} alt="Gallery2" />
          </span>
          <span className="image main">
            <img src={gallery20} alt="Gallery2" />
          </span>
          <span className="image main">
            <img src={gallery21} alt="Gallery2" />
          </span>
          <span className="image main">
            <img src={gallery22} alt="Gallery2" />
          </span>
          <span className="image main">
            <img src={gallery23} alt="Gallery2" />
          </span>
          <span className="image main">
            <img src={gallery24} alt="Gallery2" />
          </span>
          <span className="image main">
            <img src={wedding} alt="Gallery2" />
          </span>
          <span className="image main">
            <img src={proMakeup} alt="Gallery2" />
          </span>
          <span className="image main">
            <img src={photoShoot} alt="Gallery2" />
          </span>
          </div>
          {close}
        </article>

        <article
          id="wedding-makeup"
          className={`${this.props.article === 'wedding-makeup' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major" lang='el'>ΝΥΦΙΚΟ ΜΑΚΙΓΙΑΖ</h2>
          <span className="image main">
            <img src={gallery16} alt="Νυφικο Μακιγιάζ" />
          </span>
          <p lang='el'>
            Το πιο σημαντικό για μια νύφη είναι να νιώθει όμορφα και να είναι ο εαυτός της . Το
            νυφικό μακιγιάζ είναι μια ειδική περίπτωση στην οποία απαιτείται συνδυασμός διαφόρων
            τεχνικών ώστε να δείχνει αψεγάδιαστο σε κάθε φωτισμό ( φυσικό είτε τεχνητό ) αλλά και
            μέσα απο τον φακό του φωτογράφου.
            Το μακιγιάζ  πρέπει να αναδεικνύει τα χαρακτηριστικά του προσώπου και οχι να τα κρύβει
            , γ αυτο και στόχος μου είναι να δημιουργήσω ένα εντυπωσιακό look το οποίο θα
            ανταποκρίνεται στην αισθητική της κάθε νύφης αλλα και στην μοναδικοτητα της.
          </p>
          {close}
        </article>

        <article
          id="eyebrow-shaping"
          className={`${this.props.article === 'eyebrow-shaping' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major" lang='el'>ΣΧΗΜΑΤΙΣΜΟΣ ΦΡΥΔΙΩΝ</h2>
          <span className="image main">
            <img src={eyebrow} alt="Σχηματισμός φρυδιών" />
          </span>
          <p lang='el'>
            Τα φρύδια είναι που δημιουργούν την αισθηση της κορνίζας του προσώπου μας.
            Μεγαλώνουν ή μικραίνουν τα μάτια μας αναλογα με το σχήμα τους ή το παχος τους και
            λειτουργούν σαν προιόν μακιγιάζ αναδεικνύωντας σωστά και τα υπόλοιπα
            χαρακτηριστικά μας. Το  σχημα των φρυδιών υποδηλώνει ακόμα και την διάθεση μας
            γι'αυτο και δεν υποτιμούμε ή αμελούμε την περιποίηση τους. Η ιδανική μορφή τους
            εξαρτάται απο το σχήμα του προσώπου μας , το σχήμα των ματιών μας αλλα και απο τις
            αποστάσεις που εχουν εκεινα μεταξυ τους αλλα και σε σχέση με τα φρύδια.
          </p>
          {close}
        </article>

        <article
          id="lash-lift"
          className={`${this.props.article === 'lash-lift' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major" lang='el'>Lash lift tint and botox</h2>
          <span className="image main">
            <img src={lashLift} alt="Lash lift tint and botox" />
          </span>
          <p lang='el'>
            Τo lash lift ειναι θεραπεία θρέψης και ενυδάτωσης που εφαρμόζουμε στις φυσικές βλεφαρίδες. Ανασηκώνει δίνοντας καμπυλότητα και χρωματίζει τις βλεφαρίδες χωρίς καθόλου extension. Είναι μια απόλυτα ασφαλής τεχνική που δεν επιβαρύνει τη σύσταση της φυσικής βλεφαρίδας. Χρησιμοποιείται υδατοδιαλυόμενη κόλλα και 2 lotion που αποτελούνται απο 90% απιονισμένο νερό σε μορφή κρέμας.Η θεραπεία αυτη συμπεριλαμβάνει βαφή (για έντονο χρώμα) και organic lash botox. Το botox είναι μια φόρμουλα με 97,7% φυσική προέλευση συστατικών που βοηθά στην ενυδάτωση και θρέψη αδύναμων βλεφαρίδων για να ειναι υγιείς και να τις προστατεύσουμε απο πρόωρη πτωση δίνοντας τους ελαστικότητα,ογκο και λάμψη. Το αποτέλεσμα διαρκει 6-8 εβδομάδες και κατά τις πρώτες 4 εβδομάδες εχουμε το μέγιστο αποτέλεσμα το οποιο σταδιακά αρχιζει και χάνεται λόγω του κύκλου ζωης των βλεφαρίδων μας. Το lash lift ειναι μια διαδικασία που διαρκει περίπου 50λεπτα (αυτο εξαρτάται σε καθε περιπτωση ξεχωριστά) και δεν προκαλεί καμια ενόχληση στα μάτια.Δεν χρειάζεται ιδιαίτερη περιποίηση.Το πρώτο 24ωρο αποφεύγουμε το νερο.
          </p>
          {close}
        </article>

        <article
          id="professional-makeup"
          className={`${this.props.article === 'professional-makeup' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major" lang='el'>ΕΠΑΓΓΕΛΜΑΤΙΚΟ ΜΑΚΙΓΙΑΖ</h2>
          <span className="image main">
            <img src={gallery20} alt="ΕΠΑΓΓΕΛΜΑΤΙΚΟ ΜΑΚΙΓΙΑΖ" />
          </span>
          <p lang='el'>
            Το μακιγιάζ είναι ένα εργαλείο το οποίο εφόσον χρησιμοποιηθεί σωστά μπορεί να σε
            μαγέψει. Ένας makeup artist πρέπει να είναι σε θέση να χρησιμοποιεί αυτό το εργαλείο με
            άνεση προκειμένου να ανταπεξέλθει στις ιδιαιτερότητες του καθε προσώπου. Το να κοιτάς
            τον εαυτό σου στον καθρέφτη και να εισαι χαρούμενος με αυτό που βλέπεις είναι η
            μεγαλύτερη ικανοποίηση για εμένα . Η αισθητική είναι κάτι τελείως προσωπικό , γι'αυτό
            τον λόγο ειναι εξαιρετικά σημαντική η επικοινωνία που θα έχεις με τον makeup artist που θα
            επιλέξεις, ώστε να μπορεί να αντιληφθεί τις ανάγκες και τις προσδοκίες σου.
          </p>
          {close}
        </article>

        <article
          id="photoshooting-makeup"
          className={`${this.props.article === 'photoshooting-makeup' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major" lang='el'>ΜΑΚΙΓΙΑΖ ΦΩΤΟΓΡΑΦΙΣΗΣ</h2>
          <span className="image main">
            <img src={gallery24} alt="Μακιγιάζ Φωτογράφισης" />
          </span>
          <p lang='el'>
            Το μακιγιάζ φωτογράφισης αποτελεί την πλεον απαιτητική εργασία για έναν makeup artist.
            Πρέπει να είναι σε θέση να λειτουργεί υπό πίεση με λιγοστό χρόνο στη διάθεση του και να
            ξέρει να "χειρίζεται" τον φακό ώστε  να μπορεί να υποστηρίξει τις απαιτήσεις του
            φωτογράφου και των συνεργατών του . Αποτελεί ενα ομαδικό project στο οποίο πρέπει να
            υπάρξει τέλεια συνεργασία και άμμεση ανταπόκριση σε κάθε απρόοπτο. Όλο αυτό σαφώς
            απαιτεί μεγάλη προυπηρεσία και εμπιστοσύνη. Έχω συνεργαστεί με αρκετούς  σχεδιαστές
            και φωτογράφους υπάρχουν διαθέσιμες συστάσεις εφόσον ζητηθούν.
          </p>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Επικοινωνια</h2>
          <form name="Contact Form" method="post" data-netlify="true" action="/thank-you" data-netlify-honeypot="bot-field">
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="Contact Form" />
            <div className="field half first">
              <label htmlFor="name">Ονομα</label>
              <input type="text" name="name" id="name" required/>
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="email" name="email" id="email" required/>
            </div>
            <div className="field half first">
              <label htmlFor="telephone">Τηλεφωνο</label>
              <input type="text" name="telephone" id="telephone" required/>
            </div>
            <div className="field half">
              <div className="select">
                <label htmlFor="interest">ΕΝΔΙΑΦΕΡΟΜΑΙ ΓΙΑ:</label>
                <select name="interest" id="interest">
                  <option default>Παρακαλώ Επιλέξτε</option>
                  <option value="Νυφικό Μακιγιάζ">Νυφικό Μακιγιάζ</option>
                  <option value="Σχηματισμός φρυδιών">Σχηματισμός φρυδιών</option>
                  <option value="Lash lift">Lash lift</option>
                  <option value="Επαγγελματικό Μακιγιάζ">Επαγγελματικό Μακιγιάζ</option>
                  <option value="Μακιγιάζ Φωτογράφισης">Μακιγιάζ Φωτογράφισης</option>
                  <option value="Άλλο">Άλλο</option>
                </select>
              </div>
            </div>
            <div className="field">
              <label htmlFor="message">Μηνυμα</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Αποστολη" className="special" />
              </li>
            </ul>
          </form>
          <ul className="icons">
            <li>
              <a href="mailto:makeupbystamyli@gmail.com" className="icon fa-envelope">
                <span className="label">Email Me</span>
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/stamyli.mua/" target="_blank" rel="noopener noreferrer" className="icon fa-instagram">
                <span className="label">Instagram</span>
              </a>
            </li>
          </ul>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
