import React from 'react'
import PropTypes from 'prop-types'
import logo from '../images/stamy-logo.png'

const Header = (props) => (
    <header id="header" style={props.timeout ? {display: 'none'} : {}}>
        <div className="logo">
            <img src={logo} alt=""></img>
        </div>
        <div className="content">
            <div className="inner">
                <h1>Makeup by Stamyli</h1>
                <p>Professional Makeup Artist - Stamatina Lioumi - Athens, Greece <br />
                Freelancer (former MAC artist) | Bridal specialist | Lash lift specialist | Brows expert</p>
            </div>
        </div>
        <nav>
            <ul>
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('intro')}}>Bio</a></li>
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('gallery')}}>Gallery</a></li>
                <li><a href="https://www.instagram.com/stamyli.mua/" target="_blank" rel="noopener noreferrer">Instagram</a></li>
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('contact')}}>ΕΠΙΚΟΙΝΩΝΙΑ</a></li>
            </ul>
        </nav>
        <div className="content-2">
            <h2>Services</h2>
        </div>
        <nav className="second-nav">
            <ul>
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('wedding-makeup')}}>ΝΥΦΙΚΟ ΜΑΚΙΓΙΑΖ</a></li>
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('professional-makeup')}}>ΕΠΑΓΓΕΛΜΑΤΙΚΟ ΜΑΚΙΓΙΑΖ</a></li>
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('photoshooting-makeup')}}>ΜΑΚΙΓΙΑΖ ΦΩΤΟΓΡΑΦΙΣΗΣ</a></li>
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('lash-lift')}}>Lash<br /> lift</a></li>
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('eyebrow-shaping')}}>ΣΧΗΜΑΤΙΣΜΟΣ ΦΡΥΔΙΩΝ</a></li>
            </ul>
        </nav>
    </header>
)

Header.propTypes = {
    onOpenArticle: PropTypes.func,
    timeout: PropTypes.bool
}

export default Header
